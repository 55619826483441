import React from "react";

interface CategoryInputProps {
  label: string;
  slug: string;
  icon: React.ElementType<{ size: number }>; // Define the icon prop type
  selected: boolean;
  onClick: (label: string, slug: string) => void;
}

const CategoryInput: React.FC<CategoryInputProps> = ({
  label,
  slug,
  icon: Icon,
  selected,
  onClick,
}) => {
  return (
    <div
      onClick={() => onClick(label, slug)}
      className={`
      rounded-xl
      border-2
      p-4
      flex
      flex-col
      gap-3
      hover:border-black
      transition
      cursor-pointer
      ${selected ? "border-black" : "border-neutral-200"}
      `}
    >
      <Icon size={30} />
      <div className="font-semibold">{label}</div>
    </div>
  );
};

export default CategoryInput;
