import axios from "axios";

export default async function getFeaturedListings(limit: number, typeOfProperty: string) {
  try {
    const listings = await axios.get(
      `/listing/listings/featured?typeOfProperty=${typeOfProperty}&limit=${limit}`,
      {
        headers: { Authorization: localStorage.getItem("token") },
      }
    );
    if (listings.data.properties.length === 0) {
      return null;
    }
    return listings;
  } catch (error: any) {
    throw new Error(error);
  }
}
