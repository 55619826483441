import React, { FC, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";

interface Props {
  href?: string;
}

const ButtonSubmit: FC<Props> = () => {
  const [searchParams] = useSearchParams();
  const minPrice = searchParams.get("minPrice");
  const maxPrice = searchParams.get("maxPrice");
  const state = searchParams.get("state");
  const location = searchParams.get("location");
  const lat = searchParams.get("lat");
  const lng = searchParams.get("lng");
  const [url, setUrl] = useState("");
  useEffect(() => {
    const url = `/listings?minPrice=${minPrice}&maxPrice=${maxPrice}&state=${state}&location=${location}&lat=${lat}&lng=${lng}&minSize=0&maxSize=1000&typeOfProperty=`;
    setUrl(url);
  }, [minPrice, maxPrice, state, location, lat, lng]);
  return (
    <Link
      to={url}
      type="button"
      className="h-14 md:h-16 w-full md:w-16 rounded-full bg-primary-6000 hover:bg-primary-700 flex items-center justify-center text-neutral-50 focus:outline-none"
    >
      <span className="mr-3 md:hidden">Search</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
        />
      </svg>
    </Link>
  );
};

export default ButtonSubmit;
