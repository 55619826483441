import React, { useCallback, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import Modal from "./Modal";
import axios, { AxiosError } from "axios";
import useLoginModal from "../../hooks/useLoginModal";
import Heading from "../Heading";
import Input from "../Inputs/Input";
import toast from "react-hot-toast";
import useRegisterModal from "../../hooks/useRegisterModal";

interface LoginFormData {
  email: string;
  password: string;
}

const LoginModal: React.FC = () => {
  const registerModal = useRegisterModal();
  const loginModal = useLoginModal();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormData>({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = useCallback(
    (data: LoginFormData) => {
      setIsLoading(true);

      axios
        .post("/auth/login", data, {
          headers: { Authorization: localStorage.getItem("token") },
        })
        .then((res) => {
          localStorage.setItem("token", res.data.token);
          loginModal.onClose();
          toast.success("You have successfully logged into your account!");
          window.location.reload();
        })
        .catch((error: AxiosError) => {
          if (error.response && error.response.status === 401) {
            toast.error("Incorrect password or email, please try again.", {
              className: "text-center",
            });
          } else {
            toast.error("Something went wrong!");
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [loginModal]
  );

  const bodyContent = (
    <div className="flex flex-col gap-4">
      <Heading title="Welcome back!" subtitle="Log into your account" />
      <Input
        id="email"
        label="Email address"
        type="email"
        disabled={isLoading}
        errors={errors}
        register={register}
        required
      />
      <Input
        id="password"
        label="Password"
        type="password"
        disabled={isLoading}
        errors={errors}
        register={register}
        required
      />
    </div>
  );

  const footerContent = (
    <div className="flex flex-col gap-4 mt-3 ">
      <hr />
      <div className="text-neutral-500 text-center mt-4 font-light">
        <div className="flex justify-center flex-row items-center gap-2">
          <div>Don't have an account?</div>
          <div
            onClick={() => {
              loginModal.onClose();
              registerModal.onOpen();
            }}
            className="
          text-neutral-800
          cursor-pointer
          hover:underline
          "
          >
            Register
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <Modal
      disabled={isLoading}
      isOpen={loginModal.isOpen}
      title="Log into your account"
      actionLabel="Log in"
      onClose={loginModal.onClose}
      onSubmit={handleSubmit(onSubmit)}
      body={bodyContent}
      footer={footerContent}
    />
  );
};

export default LoginModal;
