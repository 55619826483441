import React, { createContext, useState, useEffect, ReactNode } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { User } from "Types/User";

interface UserContextType {
  user: User | null;
  isAuthenticated: boolean;
}
interface UserContextProviderProps {
  children: ReactNode;
}
export const UserContext = createContext<UserContextType>({
  user: null,
  isAuthenticated: false,
});

const UserContextProvider: React.FC<UserContextProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    const loadUser = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const res = await axios.get<User>("/auth/user", {
            headers: { Authorization: token },
          });
          if (res.data.email) {
            setUser(res.data);
            setIsAuthenticated(true);
          }
        } catch (err: any) {
          setIsAuthenticated(false);
          localStorage.removeItem("token");
        }
      } else {
        setIsAuthenticated(false);
        localStorage.removeItem("token");
      }
    };

    loadUser();
  }, []);

  return <UserContext.Provider value={{ user, isAuthenticated }}>{children}</UserContext.Provider>;
};

export default UserContextProvider;
