import React, { useEffect, useState } from "react";

interface HeadingProps {
  title: string;
  subtitle: string;
  center?: boolean;
  isLoaded?: boolean;
}

const Heading: React.FC<HeadingProps> = ({ title, subtitle, center, isLoaded }) => {
  const [shouldShow, setShouldShow] = useState(true);

  useEffect(() => {
    if (isLoaded !== undefined && isLoaded !== null && isLoaded === false) {
      setShouldShow(false);
    } else {
      setShouldShow(true);
    }
  }, [isLoaded]);

  return (
    <div className={`${center ? "text-center" : "text-start"}`}>
      <div
        className={`text-2xl font-semibold ${
          !shouldShow ? "bg-neutral-400 w-20 h-5 rounded-xl animate-pulse" : "animate-appear"
        }`}
      >
        {shouldShow ? title : ""}
      </div>
      <div
        className={`font-light text-neutral-500 mt-2 ${
          !shouldShow ? "bg-neutral-400 w-28 h-5 rounded-xl animate-pulse" : "animate-appear"
        }`}
      >
        {shouldShow ? subtitle : ""}
      </div>
    </div>
  );
};

export default Heading;
