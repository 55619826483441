import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import Modal from "./Modal";
import usePropertyListModal from "../../hooks/usePropertyListModal";
import Heading from "../Heading";
import { useForm } from "react-hook-form";
import { useDropzone } from "react-dropzone";
import CategoryInput from "../Inputs/CategoryInput";
import CountrySelect from "../Inputs/CountrySelect";
import Map from "../Map";
import Counter from "../Inputs/Counter";
import { AiOutlineClose } from "react-icons/ai";
import { IoIosResize } from "react-icons/io";
import toast from "react-hot-toast";
import Input from "../Inputs/Input";
import axios, { AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
import TextArea from "../Inputs/TextArea";
import CheckBox from "../Inputs/CheckBox";
import { FaHome } from "react-icons/fa";
import {
  MdAccessible,
  MdApartment,
  MdContactPhone,
  MdElevator,
  MdFitnessCenter,
  MdLocalLaundryService,
  MdLocalParking,
  MdNaturePeople,
  MdPeople,
  MdPets,
  MdPool,
  MdSmartphone,
  MdStorage,
  MdTv,
  MdVideocam,
  MdVolumeUp,
  MdWifi,
} from "react-icons/md";
import CitySelect from "components/Inputs/CitySelect";
import AddressInput from "components/Inputs/AddressInput";
import { LatLngTuple } from "leaflet";
import useLoginModal from "hooks/useLoginModal";
import { UserContext } from "UserContext";
import AmenitiesInput from "components/Inputs/AmenitiesInput";
import useCountries from "hooks/useCountries";
import getListingById from "actions/getListingById";
import usePropertyEditModal from "hooks/usePropertyEditModal";
import { IMAGE_URL, IS_PRODUCTION } from "config/config";

export const categories = [
  {
    label: "Apartment",
    slug: "apartment",
    icon: MdApartment,
    description: "Type: Apartment",
  },
  {
    label: "House",
    slug: "house",
    icon: FaHome,
    description: "Type: House",
  },
];
interface Amenity {
  id: number;
  label: string;
  slug: string;
  icon: string;
  description: string;
}
export const amenities = [
  {
    id: 1,
    label: "On-site Parking",
    slug: "on-site-parking",
    icon: MdLocalParking,
    description: "Designated parking spaces available within the apartment complex premises.",
  },
  {
    id: 2,
    label: "Elevator",
    slug: "elevator",
    icon: MdElevator,
    description:
      "An elevator is available for easy access to different floors of the apartment building, particularly useful in multi-story buildings.",
  },
  {
    id: 3,
    label: "Security Cameras",
    slug: "security-cameras",
    icon: MdVideocam,
    description:
      "Surveillance cameras installed in common areas for added security and monitoring.",
  },
  {
    id: 4,
    label: "Intercom/Doorbell",
    slug: "intercom-doorbell",
    icon: MdVolumeUp,
    description:
      "An intercom or doorbell system for communication and allowing entry to the apartment.",
  },
  {
    id: 5,
    label: "High-Speed Internet Connection",
    slug: "high-speed-internet",
    icon: MdWifi,
    description: "A fast and reliable internet connection for residents' use.",
  },
  {
    id: 6,
    label: "Cable or Satellite TV Connection",
    slug: "tv-connection",
    icon: MdTv,
    description: "Access to cable or satellite television services for entertainment.",
  },
  {
    id: 7,
    label: "Washer and Dryer",
    slug: "washer-dryer",
    icon: MdLocalLaundryService,
    description: "In-unit or shared facilities for washing and drying clothes.",
  },
  {
    id: 8,
    label: "Storage Spaces",
    slug: "storage-spaces",
    icon: MdStorage,
    description: "Additional storage areas such as closets, cabinets, or basement/attic storage.",
  },
  {
    id: 9,
    label: "Gym or Fitness Center",
    slug: "gym-fitness-center",
    icon: MdFitnessCenter,
    description: "An on-site gym or fitness facility for residents' use.",
  },
  {
    id: 10,
    label: "Swimming Pool",
    slug: "swimming-pool",
    icon: MdPool,
    description: "An outdoor or indoor swimming pool available for residents' use.",
  },
  {
    id: 11,
    label: "Lounge or Community Room",
    slug: "lounge-community-room",
    icon: MdPeople,
    description: "A shared space for residents to relax, socialize, or host gatherings.",
  },
  {
    id: 12,
    label: "Outdoor Spaces (Garden or Patio)",
    slug: "outdoor-spaces",
    icon: MdNaturePeople,
    description: "Garden areas, patios, or outdoor seating spaces for residents' enjoyment.",
  },
  {
    id: 13,
    label: "Wheelchair Ramps or Accessible Entrances",
    slug: "wheelchair-accessibility",
    icon: MdAccessible,
    description:
      "Features ensuring accessibility for residents with mobility challenges, such as ramps and accessible entrances.",
  },
  {
    id: 14,
    label: "Property Management Contact Information",
    slug: "property-management",
    icon: MdContactPhone,
    description:
      "Contact information for property management for residents' convenience in reporting issues or seeking assistance.",
  },
  {
    id: 15,
    label: "Pet-Friendly Policy",
    slug: "pet-friendly-policy",
    icon: MdPets,
    description:
      "A policy allowing residents to have pets, along with any associated amenities such as a dog park or grooming station.",
  },
  {
    id: 16,
    label: "Smart Home Features",
    slug: "smart-home-features",
    icon: MdSmartphone,
    description:
      "Technological features such as smart thermostats or locks that enhance convenience and efficiency.",
  },
];

const STEPS = {
  CATEGORY: 0,
  AMENITIES: 1,
  LOCATION: 2,
  INFO: 3,
  IMAGES: 4,
  DESCRIPTION: 5,
  PRICE: 6,
};

interface PropertyPostModalProps {}

interface Location {
  value: string;
  label: string;
  latlang: string;
}

interface City {
  name: string;
  coords: [number, number];
}

interface FormData {
  title: string;
  category: string;
  categorySlug: string;
  roomCount: number;
  bathroomCount: number;
  guestCount: number;
  bedCount: number;
  images: Blob[];
  price: number;
  propertyPrice: number;
  priceNegotiable: boolean;
  description: string;
  size: string;
  location: Location | null;
  city: City | null;
  address: string;
  locationCoords: LatLngTuple;
  amenities: [];
}
export interface Listing {
  id: number;
  ownerId: number;
  name: string;
  pricePerNight: number;
  category: string;
  galleryImgs: string;
  listingCat: string;
  categorySlug: string;
  roomCount: number;
  bathroomCount: number;
  guestCount: number;
  bedCount: number;
  images: string;
  price: number;
  propertyPrice: number;
  priceNegotiable: boolean;
  description: string;
  size: string;
  location: Location | null;
  city: City | null;
  address: string;
  locationCoords: LatLngTuple;
  lat: number;
  lng: number;
  amenities: [];
}
interface Loc {
  latlang: [number, number];
}
export type PropertyPostState = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
};
const PropertyEditModal: React.FC<PropertyPostModalProps> = () => {
  const [step, setStep] = useState<number>(STEPS.CATEGORY);
  const [selectedLocation, setSelectedLocation] = useState<Loc>({ latlang: [1, 2] });
  const [selectedCity, setSelectedCity] = useState<City>({ name: "Select a city", coords: [1, 1] });
  const [selectedAddress, setSelectedAddress] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const router = useNavigate();
  const [selectedAmenities, setSelectedAmenities] = useState<string[]>([]);
  const propertyEditModal = usePropertyEditModal();
  const [fetchedImages, setFetchedImages] = useState([]);
  const [initialData, setInitialData] = useState<Listing>({
    id: 0,
    ownerId: 0,
    name: "",
    category: "",
    pricePerNight: 0,
    listingCat: "",
    categorySlug: "",
    location: null,
    city: null,
    address: "",
    roomCount: 1,
    bathroomCount: 1,
    guestCount: 1,
    bedCount: 1,
    images: "",
    price: 1,
    propertyPrice: 1,
    priceNegotiable: false,
    description: "",
    size: "",
    locationCoords: [1, 1],
    lat: 1,
    lng: 1,
    galleryImgs: "",
    amenities: [],
  });
  useEffect(() => {
    const getListingData = async () => {
      const listingData = await getListingById(Number(propertyEditModal.listingId));
      if (listingData) {
        setInitialData(
          listingData || {
            id: 0,
            ownerId: 0,
            name: "",
            category: "",
            pricePerNight: 0,
            listingCat: "",
            categorySlug: "",
            location: null,
            city: null,
            address: "",
            roomCount: 1,
            bathroomCount: 1,
            guestCount: 1,
            bedCount: 1,
            images: "",
            price: 1,
            propertyPrice: 1,
            priceNegotiable: false,
            description: "",
            size: "",
            locationCoords: [1, 1],
            lat: 1,
            lng: 1,
            galleryImgs: "",
            amenities: [],
          }
        );
      }
    };
    getListingData();
  }, [propertyEditModal.isOpen]);
  const toggleAmenity = (label: string, slug: string) => {
    const index = selectedAmenities.indexOf(label);
    if (index === -1) {
      setSelectedAmenities([...selectedAmenities, label]);
    } else {
      setSelectedAmenities(selectedAmenities.filter((cat) => cat !== label));
    }
  };
  const propertyEdit = usePropertyEditModal() as PropertyPostState;
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    reset,
  } = useForm<FormData>({
    defaultValues: {
      title: "",
      category: "",
      categorySlug: "",
      location: null,
      city: null,
      address: "",
      roomCount: 1,
      bathroomCount: 1,
      guestCount: 1,
      bedCount: 1,
      images: [],
      price: 1,
      propertyPrice: 1,
      priceNegotiable: false,
      description: "",
      size: "",
      locationCoords: [1, 1],
      amenities: [],
    },
  });
  const setCustomValue = (id: any, value: any) => {
    setValue(id, value, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
  };
  useEffect(() => {
    //@ts-ignore
    setCustomValue("images", []);
    setFetchedImages([]);
    setCustomValue("title", initialData.name || "");
    setCustomValue("location", initialData.city || "");
    //@ts-ignore
    setSelectedCity({ name: initialData.stateName, coords: [1, 1] });
    //@ts-ignore
    setCustomValue("city", initialData.stateName || "");
    setCustomValue("address", initialData.address || "");
    setCustomValue("category", initialData.listingCat || "");
    setCustomValue("listingCat", initialData.listingCat || "");
    setCustomValue("categorySlug", initialData.categorySlug);
    setCustomValue("roomCount", initialData.roomCount);
    setCustomValue("bedCount", initialData.bedCount);
    setCustomValue("bathroomCount", initialData.bathroomCount);
    setCustomValue("description", initialData.description);
    setCustomValue("propertyPrice", initialData.price);
    setCustomValue("price", initialData.pricePerNight);
    setCustomValue("size", initialData.size);
    setCustomValue("locationCoords", [Number(initialData.lat), Number(initialData.lng)]);
    //@ts-ignore
    const amns = initialData.amenities
      .toString()
      .split(",")
      .map((id) => {
        //@ts-ignore
        const amenity = amenities.find((a) => a.id === Number(id));
        return amenity ? amenity.label : "";
      });
    //@ts-ignore
    setSelectedAmenities(amns);
    setSelectedAddress(initialData.address);
    setSelectedLocation({ latlang: [Number(initialData.lat), Number(initialData.lng)] });
    //@ts-ignore
    setSelectedCity({
      //@ts-ignore
      name: initialData.stateName,
    });
    setCustomValue("city", {
      //@ts-ignore
      name: initialData.stateName,
    });
    initialData.images?.split(",").forEach(async (img) => {
      const imagePromises = initialData.images?.split(",").map(async (img) => {
        const response = await fetch(`${IMAGE_URL + img}`);
        const blob = await response.blob();
        const file = new File([blob], `${Math.floor(Math.random() * 1000) + "image.jpg"}`, {
          type: "image/jpeg",
        });
        return file;
      });
      Promise.all(imagePromises).then((imageFiles) => {
        //@ts-ignore
        setFetchedImages(imageFiles);
      });
    });
  }, [initialData]);
  useEffect(() => {
    if (Number(images.length) < Number(initialData.images?.split(",").length)) {
      setCustomValue("images", fetchedImages);
    }
  }, [fetchedImages]);
  const images = watch("images");

  const ImageUpload: React.FC = () => {
    const onDrop = (acceptedFiles: File[]) => {
      if (images.length + acceptedFiles.length > 6) {
        toast.error("You can only upload up to 6 images!");
        return;
      }
      setCustomValue("images", [...images, ...acceptedFiles]);
    };

    const { getRootProps, getInputProps } = useDropzone({
      accept: {
        "image/*": [".png", ".jpg", ".jpeg"],
      },
      multiple: true,
      onDrop,
    });

    const handleRemoveImage = (index: number) => {
      const updatedImages = images.filter((_: any, i: number) => i !== index);
      setCustomValue("images", updatedImages);
    };

    return (
      <div className="flex flex-row items-center gap-3 justify-center">
        <div className="flex flex-col items-center justify-center">
          <div className="mb-6" {...getRootProps()}>
            <input {...getInputProps()} />
            <div className="bg-gray-100 border border-gray-300 p-6 rounded cursor-pointer flex items-center justify-center flex-col">
              <p className="text-gray-700 font-bold mb-2">
                Place your photos here by dragging them.
              </p>
              <p className="text-gray-500">or</p>
              <label
                htmlFor="imageInput"
                className="cursor-pointer bg-primary hover:opacity-80 text-gray-700 font-bold py-2 px-4 rounded inline-block mt-2"
              >
                click to upload them
              </label>
              <p className="text-sky-700 text-xl font-bold text-center">
                Photos will be displayed in the order you upload them in.
              </p>
            </div>
          </div>
          {images.length > 0 && (
            <div className="flex flex-wrap justify-center relative overflow-y-auto max-h-40">
              {images.map((image: Blob, index: number) => (
                <div key={index} className="relative">
                  <img
                    src={URL.createObjectURL(image) || ""}
                    alt={`uploaded ${index + 1}`}
                    className="w-32 h-32 object-cover m-2 rounded"
                  />
                  <button
                    onClick={() => handleRemoveImage(index)}
                    className="absolute top-0 right-0 text-red-700"
                  >
                    <AiOutlineClose size={30} />
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  };
  const { getAll } = useCountries();
  const category = watch("category");
  const categorySlug = watch("categorySlug");
  const location = watch("location");
  const cityWatch = watch("city") || { name: "" };

  const addressWatch = watch("address");
  const roomCount = watch("roomCount");
  const bathroomCount = watch("bathroomCount");
  const guestCount = watch("guestCount");
  const bedCount = watch("bedCount");
  const title = watch("title");
  const description = watch("description");
  const price = watch("price");
  const size = watch("size");
  const priceNegotiable = watch("priceNegotiable");
  const propertyPrice = watch("propertyPrice");
  const locationCoords = watch("locationCoords");
  useEffect(() => {
    const allCountries = getAll();
    const country = allCountries.find((country) => country.label == location?.label);
    //@ts-ignore
    setSelectedLocation(country);
  }, [location]);

  const onBack = () => {
    setStep((value) => value - 1);
  };

  const onNext = () => {
    setStep((value) => value + 1);
  };

  const onSubmit = async (data: FormData) => {
    if (step !== STEPS.PRICE) {
      return onNext();
    }

    const formData = new FormData();
    images.forEach((image: any, index: number) => {
      formData.append(`images`, image);
    });
    formData.append("category", category);
    formData.append("title", title);
    formData.append("categoryslug", categorySlug);
    formData.append("roomCount", roomCount.toString());
    formData.append("bathroomCount", roomCount.toString());
    formData.append("guestCount", guestCount.toString());
    formData.append("bedCount", bedCount.toString());
    formData.append("countryCode", location?.value || "");
    formData.append("city", location?.label || "");
    formData.append("stateName", cityWatch?.name.toString() || "");
    formData.append("address", addressWatch);
    formData.append("latlng", location?.latlang || "");
    //@ts-ignore
    formData.append("images", images);
    formData.append("price", price.toString());
    formData.append("priceNegotiable", priceNegotiable.toString());
    formData.append("propertyPrice", propertyPrice.toString());
    formData.append("description", description || "");
    formData.append("size", size || "");
    formData.append("lat", locationCoords[0].toString());
    formData.append("lng", locationCoords[1].toString());
    const selectedAmenityIds = selectedAmenities.map((label) => {
      const matchingAmenity = amenities.find((amenity) => amenity.label === label);
      return matchingAmenity ? matchingAmenity.id : null;
    });
    for (let i = 0; i < selectedAmenities.length; i++) {
      //@ts-ignore
      formData.append("amenities[]", selectedAmenityIds[i]?.toString());
    }

    setIsLoading(true);
    try {
      const res: AxiosResponse = await axios.post(`/listing/edit/${initialData.id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("token") || "",
        },
      });

      toast.promise(
        Promise.resolve(res),
        {
          loading: "Creating...",
          success: (
            <b>
              Listing successfully edited! Our team will take a look at the listing and if
              everything is OK, we will approve it.
            </b>
          ),
          error: <b>Something went wrong.</b>,
        },
        { duration: 6000 }
      );

      // router("/");
      setStep(STEPS.CATEGORY);
      propertyEdit.onClose();
    } catch (error) {
      console.error(error);
    } finally {
      reset();
      setSelectedAmenities([]);
      setSelectedAddress("");
      setIsLoading(false);
    }
  };

  const checkNextDisabled = useMemo(() => {
    if (step === STEPS.CATEGORY && category.length >= 1 && title.length > 3) {
      return false;
    }
    if (step === STEPS.AMENITIES && selectedAmenities.length >= 1) {
      return false;
    }
    if (
      step === STEPS.LOCATION &&
      location?.label &&
      addressWatch.length > 3 &&
      selectedCity.name.length > 2
    ) {
      return false;
    }
    if (step === STEPS.INFO) {
      return false;
    }
    if (step === STEPS.DESCRIPTION && description.length >= 5) {
      return false;
    }
    if (step === STEPS.IMAGES && images.length >= 3) {
      return false;
    }
    if ((price > 0 || priceNegotiable === true) && Number(size) >= 3) {
      return false;
    }
    return true;
  }, [
    category,
    description,
    location,
    cityWatch,
    addressWatch,
    images,
    price,
    size,
    step,
    priceNegotiable,
    title,
    propertyEdit.isOpen,
  ]);

  const actionLabel = useMemo(() => {
    if (step === STEPS.PRICE) {
      return "Edit";
    }

    return "Next";
  }, [step]);

  const secondaryActionLabel = useMemo(() => {
    if (step === STEPS.CATEGORY) {
      return undefined;
    }
    return "Back";
  }, [step]);

  let bodyContent: JSX.Element | null = null;

  if (step === STEPS.CATEGORY) {
    bodyContent = (
      <div className="flex flex-col gap-8">
        <Heading title={"Choose the property's type"} subtitle={"Choose from the following"} />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3 max-h-[50vh] overflow-y-auto">
          {categories.map((item: any) => (
            <div key={item.label} className="col-span-1">
              <CategoryInput
                onClick={(label: string, slug: string) => {
                  setCustomValue("category", label);
                  setCustomValue("categorySlug", slug);
                }}
                selected={category === item.label}
                label={item.label}
                slug={item.slug}
                icon={item.icon}
              />
            </div>
          ))}
        </div>
        <div className="w-full relative">
          <span className="w-6 h-6 text-neutral-700 absolute top-5 left-2"></span>
          <input
            id="title"
            disabled={isLoading}
            {...register("title", { required: true })}
            placeholder=" "
            type="text"
            className={`
        peer
        w-full
        p-4
        pt-6
        font-semibold
        bg-white
        border-2
        rounded-md
        outline-none
        transition
        disabled:opacity-70
        disabled:cursor-not-allowed
        pl-4
        `}
          />
          <label
            className={`
      absolute
      text-md
      duration-150
      transform
     
      -translate-y-3
      top-4
      z-10
      origin-[0]
      left-4
      peer-placeholder-shown:scale-100
      peer-placeholder-shown:translate-y-0
      peer-focus:scale-75
      peer-focus:-translate-y-4
      `}
          >
            Name of the property
          </label>
        </div>
      </div>
    );
  }
  if (step === STEPS.AMENITIES) {
    bodyContent = (
      <div className="flex flex-col gap-8">
        <Heading
          title={"Choose the property's available amenities"}
          subtitle={"Choose from the following"}
        />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3 max-h-[50vh] overflow-y-auto">
          {amenities.map((item: any) => (
            <div key={item.label} className="col-span-1">
              <AmenitiesInput
                onClick={(label: string, slug: string) => toggleAmenity(label, slug)}
                selected={selectedAmenities.includes(item.label)}
                label={item.label}
                slug={item.slug}
                icon={item.icon}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }

  if (step === STEPS.LOCATION) {
    bodyContent = (
      <div className="flex flex-col gap-8">
        <Heading title={"Where is the property located"} subtitle={"Choose location"} />
        <CountrySelect
          value={location}
          onChange={(value: any) => {
            setCustomValue("location", value);
            setSelectedLocation(value);
            setSelectedCity({ name: "Select a city", coords: [1, 1] });
            setValue("locationCoords", value.latlang);
          }}
        />
        {selectedLocation?.latlang[0] > 2 ? (
          <CitySelect
            value={cityWatch}
            country={selectedLocation}
            onChange={(value: any) => {
              setCustomValue("city", value);
              setSelectedCity(value);
            }}
          />
        ) : (
          ""
        )}
        <AddressInput
          value={selectedAddress}
          onChange={(value: any) => {
            setCustomValue("address", value);
            setSelectedAddress(value);
          }}
        />
        <Map
          key={`${selectedLocation?.latlang[0]}-${selectedLocation?.latlang[1]}`}
          center={locationCoords}
          onLocationChange={(loc) => {
            setCustomValue("locationCoords", loc);
          }}
          onCountryChange={(cntr) => {
            setCustomValue("location", {
              //@ts-ignore
              label: cntr,
            });
            setSelectedCity({ name: "Select a city", coords: [1, 1] });
          }}
        />
      </div>
    );
  }

  if (step === STEPS.INFO) {
    bodyContent = (
      <div className="flex flex-col gap-8">
        <Heading title={"Room and guest information"} subtitle={"Rooms, guests and beds"} />
        <Counter
          title="Rooms"
          subtitle="How many rooms does the property have?"
          value={roomCount}
          onChange={(value: any) => {
            setCustomValue("roomCount", value);
          }}
        />
        <Counter
          title="Bathrooms"
          subtitle="How many bathrooms does the property have?"
          value={bathroomCount}
          onChange={(value: any) => {
            setCustomValue("bathroomCount", value);
          }}
        />
        <Counter
          title="Guests"
          subtitle="How many guests does the property allow?"
          value={guestCount}
          onChange={(value: any) => {
            setCustomValue("guestCount", value);
          }}
        />
        <Counter
          title="Beds"
          subtitle="How many beds does the property have?"
          value={bedCount}
          onChange={(value: any) => {
            setCustomValue("bedCount", value);
          }}
        />
      </div>
    );
  }

  if (step === STEPS.IMAGES) {
    bodyContent = (
      <div className="flex flex-col gap-8">
        <Heading title={"Upload images of the property"} subtitle={"Upload images"} />
        <ImageUpload />
      </div>
    );
  }

  if (step === STEPS.DESCRIPTION) {
    bodyContent = (
      <div className="flex flex-col gap-8">
        <Heading title={"Describe the property"} subtitle={"Enter a description"} />
        <TextArea
          id="description"
          label="Description"
          type="text"
          value={description}
          disabled={isLoading}
          register={register}
          errors={errors}
          required
        />
      </div>
    );
  }

  if (step === STEPS.PRICE) {
    bodyContent = (
      <div className="flex flex-col gap-8">
        <Heading title={"Prices and size"} subtitle={"Enter prices and size"} />
        <div className="w-full relative">
          <span className="w-6 h-6 text-neutral-700 absolute top-5 left-2">€</span>
          <input
            id="price"
            disabled={isLoading || priceNegotiable}
            {...register("price", { required: true })}
            placeholder=" "
            type="number"
            className={`
        peer
        w-full
        p-4
        pt-6
        font-semibold
        bg-white
        border-2
        rounded-md
        outline-none
        transition
        disabled:opacity-70
        disabled:cursor-not-allowed
        ${true ? "pl-9" : "pl-4"}
        `}
          />
          <label
            className={`
      absolute
      text-md
      duration-150
      transform
     
      -translate-y-3
      top-4
      z-10
      origin-[0]
      ${true ? "left-9" : "left-4"}
      peer-placeholder-shown:scale-100
      peer-placeholder-shown:translate-y-0
      peer-focus:scale-75
      peer-focus:-translate-y-4
      `}
          >
            Price per night
          </label>
        </div>
        <div className="w-full relative">
          <span className="w-6 h-6 text-neutral-700 absolute top-5 left-2">€</span>
          <input
            id="propertyPrice"
            disabled={isLoading || priceNegotiable}
            {...register("propertyPrice", { required: true })}
            placeholder=" "
            type="number"
            className={`
        peer
        w-full
        p-4
        pt-6
        font-semibold
        bg-white
        border-2
        rounded-md
        outline-none
        transition
        disabled:opacity-70
        disabled:cursor-not-allowed
        ${true ? "pl-9" : "pl-4"}
        `}
          />
          <label
            className={`
      absolute
      text-md
      duration-150
      transform
     
      -translate-y-3
      top-4
      z-10
      origin-[0]
      ${true ? "left-9" : "left-4"}
      peer-placeholder-shown:scale-100
      peer-placeholder-shown:translate-y-0
      peer-focus:scale-75
      peer-focus:-translate-y-4
      `}
          >
            Price for the property
          </label>
        </div>
        {/* <div className="flex items-center">
          <input
            id="priceNegotiable"
            type="checkbox"
            {...register("priceNegotiable")}
            className={`
            peer
            w-6 h-6
            border-2
            checked:bg-primary checked:border-primary
            rounded-md
            outline-none
            transition
            disabled:opacity-70
            disabled:cursor-not-allowed
          `}
          />
          <label
            className={`
            text-md
            duration-150
            transform
            pl-2
          `}
          >
            Цена по договаряне
          </label>
        </div> */}

        <div className="w-full relative">
          <span className="w-4 h-4 text-neutral-700 absolute top-5 left-2 text-s">m2</span>
          <input
            id="size"
            disabled={isLoading}
            {...register("size", { required: true })}
            placeholder=" "
            type="number"
            className={`
        peer
        w-full
        p-4
        pt-6
        font-semibold
        bg-white
        border-2
        rounded-md
        outline-none
        transition
        disabled:opacity-70
        disabled:cursor-not-allowed
        ${true ? "pl-9" : "pl-4"}
        `}
          />
          <label
            className={`
      absolute
      text-md
      duration-150
      transform
      -translate-y-3
      top-4
      z-10
      origin-[0]
      ${true ? "left-9" : "left-4"}
      peer-placeholder-shown:scale-100
      peer-placeholder-shown:translate-y-0
      peer-focus:scale-75
      peer-focus:-translate-y-4
      `}
          >
            Size
          </label>
        </div>
      </div>
    );
  }
  return (
    <Modal
      isOpen={propertyEdit.isOpen}
      onClose={propertyEdit.onClose}
      onSubmit={handleSubmit(onSubmit)}
      actionDisabled={checkNextDisabled}
      actionLabel={actionLabel}
      secondaryActionLabel={secondaryActionLabel}
      secondaryAction={step === STEPS.CATEGORY ? undefined : onBack}
      title="Edit listing"
      body={bodyContent}
      footer=""
      disabled={isLoading}
    />
  );
};

export default PropertyEditModal;
