// AddressInput.tsx
import React from "react";

interface AddressInputProps {
  value: string;
  onChange: (value: string) => void;
}

const AddressInput: React.FC<AddressInputProps> = ({ value, onChange }) => {
  return (
    <div className="z-30">
      <input
        className="rounded-md h-16 text-black bg-white"
        type="text"
        placeholder="Enter Address"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
};

export default AddressInput;
