import React from "react";

const LogoSvgLight = () => {
  return (
    <div style={{ position: "relative" }}>
      {/* <div
        className="circle"
        style={{
          backgroundImage: 'url("/assets/flags/bg-flag.svg")',
          width: "25px",
          height: "25px",
          backgroundSize: "cover",
          backgroundPosition: "center",
          borderRadius: "50%",
          position: "absolute",
          top: "6px",
        }}
      ></div> */}
      <img src="/../assets/images/logo-trybuyclub.png" alt="" />
    </div>
  );
};

export default LogoSvgLight;
