import { User } from "Types/User";
import axios, { AxiosResponse } from "axios";

export default async function getHosts(): Promise<User[] | null> {
  try {
    const response: AxiosResponse<User[]> = await axios.get(`/users/hosts`, {
      headers: { Authorization: localStorage.getItem("token") },
    });
    //@ts-ignore
    const hostsData = response.data.hosts;

    if (hostsData[0].id <= 0) {
      return null;
    }
    return hostsData;
  } catch (error: any) {
    throw new Error(error);
  }
}
