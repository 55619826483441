import React, { FC, useState } from "react";
import LocationInput from "../LocationInput";
import PriceRangeInput from "./PriceRangeInput";
import PropertyTypeSelect from "./PropertyTypeSelect";
import PriceRangeInputListings from "./PriceRangeInputListings";
import SizeRangeInput from "./SizeRangeInput";
import ButtonSubmit from "../ButtonSubmit";
import { useNavigate, useSearchParams } from "react-router-dom";
import LocationInputListings from "../LocationInputListings";

export interface RealEstateSearchFormListingsProps {}

const RealEstateSearchFormListings: FC<RealEstateSearchFormListingsProps> = ({}) => {
  const [rangePrices, setRangePrices] = useState<[number, number]>([100000, 4000000]);
  const [rangeSizes, setRangeSizes] = useState<[number, number]>([0, 1000]);
  const [selectedCountry, setSelectedCountry] = useState<string>("");
  const [selectedCity, setSelectedCity] = useState<string>("");
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const searchListings = () => {
    console.log(selectedCity, selectedCountry);
    navigate(
      `/listings?minPrice=${rangePrices[0]}&maxPrice=${rangePrices[1]}&state=${selectedCity}&location=${selectedCountry}&lat=64.00000000&lng=null&minSize=${rangeSizes[0]}&maxSize=${rangeSizes[1]}&typeOfProperty=`
    );
    window.location.reload();
  };

  return (
    <form className="w-full relative xl:mt-8 flex flex-col lg:flex-row lg:items-center rounded-3xl lg:rounded-full shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800 divide-y divide-neutral-200 dark:divide-neutral-700 lg:divide-y-0">
      <LocationInputListings
        onLocationChange={(selectedLocation, selectedState) => {
          setSelectedCountry(selectedLocation);
          setSelectedCity(selectedState);
        }}
        className="flex-[1.5]"
      />

      <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
      <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
      <SizeRangeInput rangeSizes={rangeSizes} setRangeSizes={setRangeSizes} />
      <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
      <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
      <PriceRangeInputListings rangePrices={rangePrices} setRangePrices={setRangePrices} />
      <div className="mr-4">
        <div
          onClick={searchListings}
          className="h-14 md:h-16 cursor-pointer w-full md:w-16 rounded-full bg-primary-6000 hover:bg-primary-700 flex items-center justify-center text-neutral-50 focus:outline-none"
        >
          <span className="mr-3 md:hidden">Search</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
        </div>
      </div>
    </form>
  );
};

export default RealEstateSearchFormListings;
