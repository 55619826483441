import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import RealEstateSearchForm from "./RealEstateSearchForm";

interface HeroRealEstateSearchFormProps {
  className?: string;
}

const HeroRealEstateSearchForm: FC<HeroRealEstateSearchFormProps> = ({ className = "" }) => {
  return (
    <div className={`nc-HeroRealEstateSearchForm w-full max-w-6xl py-5 lg:py-0 ${className}`}>
      <RealEstateSearchForm />
    </div>
  );
};

export default HeroRealEstateSearchForm;
