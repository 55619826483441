import React from "react";
import useCountries from "../../hooks/useCountries";
import Select from "react-select";

interface CountrySelectProps {
  value: any;
  onChange: (value: any) => void;
}

const CountrySelect: React.FC<CountrySelectProps> = ({ value, onChange }) => {
  const { getAll } = useCountries();
  return (
    <div className="z-50">
      <Select
        placeholder="Anywhere"
        options={getAll()}
        value={value}
        onChange={(value: string) => onChange(value)}
        formatOptionLabel={(option: any) => (
          <div className="flex flex-row items-center gap-3 z-50">
            {/* <div>{option.flag}</div> */}
            <div className="text-[#111827] z-50">
              {option.label}
              <span className="text-neutral-500 ml-1">{/* {option.region} */}</span>
            </div>
          </div>
        )}
        classNames={{
          control: () => "p-3 border-2",
          input: () => "text-lg",
          option: () => "text-lg",
        }}
        theme={(theme: any) => ({
          ...theme,
          borderRadius: 6,
          colors: {
            ...theme.colors,
            primary: "#1f4c6f",
            primary25: "#ffe4e6",
          },
        })}
        styles={{
          option: (provided: any, state: any) => ({
            ...provided,
            backgroundColor: state.isFocused ? "#ffffff" : "transparent", // Change background color when option is focused
            color: state.isSelected ? "white" : "#111827", // Change text color when option is selected
            "&:hover": {
              backgroundColor: "#f0f0f0", // Change background color on hover
            },
          }),
        }}
      />
    </div>
  );
};

export default CountrySelect;
