import axios from "axios";

export default async function getListings(
  minPrice: number,
  maxPrice: number,
  minSize: number,
  maxSize: number,
  limit: number,
  adultGuests: number,
  childrenGuests: number,
  infantGuests: number,
  country: string,
  state: string,
  startDate: Date,
  endDate: Date,
  lat: number,
  lng: number,
  typeOfProperty: string
) {
  try {
    const listings = await axios.get(
      `/listing/listings?minPrice=${minPrice}&maxPrice=${maxPrice}
      &minSize=${minSize}
      &maxSize=${maxSize}
      &adultGuests=${adultGuests}
      &childrenGuests=${childrenGuests}
      &infantGuests=${infantGuests}
      &country=${country}
      &state=${state}
      &startDate=${startDate}
      &endDate=${endDate}
      &lat=${lat}
      &lng=${lng}
      &typeOfProperty=${typeOfProperty}
      &limit=${limit}`,
      {
        headers: { Authorization: localStorage.getItem("token") },
      }
    );
    if (listings.data.properties.length === 0) {
      return null;
    }
    return listings;
  } catch (error: any) {
    throw new Error(error);
  }
}
