import React, { useCallback, useState } from "react";
import { useForm, SubmitHandler, SubmitErrorHandler } from "react-hook-form";
import Modal from "./Modal";
import axios from "axios";
import useRegisterModal from "../../hooks/useRegisterModal";
import Heading from "../Heading";
import toast from "react-hot-toast";
import useLoginModal from "../../hooks/useLoginModal";
import Input from "components/Inputs/Input";

interface FormData {
  name: string;
  email: string;
  password: string;
  tel: string;
}

const RegisterModal: React.FC = () => {
  const registerModal = useRegisterModal();
  const loginModal = useLoginModal();
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      name: "",
      email: "",
      password: "",
      tel: "",
    },
  });
  const password = watch("password");
  const email = watch("email");

  const onSubmit: SubmitHandler<FormData> = (data) => {
    if (password.length >= 6 && email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) {
      setIsLoading(true);

      axios
        .post("/auth/register", data, {
          headers: { Authorization: localStorage.getItem("token") },
        })
        .then((data) => {
          registerModal.onClose();
          toast.success("Account registered successfully");
          loginModal.onOpen();
          setIsLoading(false);
          reset();
        })
        .catch((error) => {
          if (Number(error.status) === 401) {
            toast.error("Invalid email address");
          } else {
            toast.error("Something went wrong");
          }
          setIsLoading(false);
        });
    } else {
      toast.error(
        "Password must be at least 8 characters long and the email address must be valid!"
      );
    }
  };

  const bodyContent = (
    <div className="flex flex-col gap-4">
      <Heading title="Welcome!" subtitle="Create an account" />
      <Input
        id="email"
        label="Email Address"
        type="email"
        disabled={isLoading}
        errors={errors}
        register={register}
        required
      />
      <Input
        id="name"
        label="Name"
        type="text"
        disabled={isLoading}
        errors={errors}
        required
        register={register}
      />
      <Input
        id="password"
        label="Password"
        type="password"
        disabled={isLoading}
        errors={errors}
        register={register}
        required
      />
      <Input
        id="tel"
        label="Phone Number"
        type="tel"
        disabled={isLoading}
        errors={errors}
        register={register}
        required
      />
    </div>
  );

  const footerContent = (
    <div className="flex flex-col gap-4 mt-3 ">
      <hr />
      <div className="text-neutral-500 text-center mt-4 font-light">
        <div className="flex justify-center flex-row items-center gap-2">
          <div>Already have an account?</div>
          <div
            onClick={() => {
              registerModal.onClose();
              loginModal.onOpen();
            }}
            className="text-neutral-800 cursor-pointer hover:underline"
          >
            Log in
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <Modal
      disabled={isLoading}
      isOpen={registerModal.isOpen}
      title="Registration"
      actionLabel="Register"
      onClose={registerModal.onClose}
      onSubmit={handleSubmit(onSubmit)}
      body={bodyContent}
      footer={footerContent}
    />
  );
};

export default RegisterModal;
