import React, { createContext, useState, useContext, ReactNode } from "react";

// Define the types for book listing information
interface BookListingContextType {
  startDate: Date | null;
  endDate: Date | null;
  guestCount: number;
  adultGuestCount: number;
  childrenGuestCount: number;
  infantGuestCount: number;
  setStartDate: (date: Date | null) => void;
  setEndDate: (date: Date | null) => void;
  setGuestCount: (count: number) => void;
  setAdultGuestCount: (count: number) => void;
  setChildrenGuestCount: (count: number) => void;
  setInfantGuestCount: (count: number) => void;
}

// Create the context
export const BookListingContext = createContext<BookListingContextType | undefined>(undefined);
export const useBookListingContext = () => {
  const context = useContext(BookListingContext);
  if (!context) {
    throw new Error("useBookListingContext must be used within a BookListingProvider");
  }
  return context;
};
interface BookListingProviderProps {
  children: ReactNode;
}
export const BookListingProvider: React.FC<BookListingProviderProps> = ({ children }) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [guestCount, setGuestCount] = useState<number>(1);
  const [adultGuestCount, setAdultGuestCount] = useState<number>(1);
  const [childrenGuestCount, setChildrenGuestCount] = useState<number>(0);
  const [infantGuestCount, setInfantGuestCount] = useState<number>(0);

  const value: BookListingContextType = {
    startDate,
    endDate,
    guestCount,
    adultGuestCount,
    childrenGuestCount,
    infantGuestCount,
    setStartDate,
    setEndDate,
    setGuestCount,
    setAdultGuestCount,
    setChildrenGuestCount,
    setInfantGuestCount,
  };

  return <BookListingContext.Provider value={value}>{children}</BookListingContext.Provider>;
};
