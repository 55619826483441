import ToasterProvider from "providers/ToasterProvider";
import FooterNav from "components/FooterNav";
// import PropertyPostModal from "containers/Modals/PropertyPostModal";
import SiteHeader from "containers/SiteHeader";
import React from "react";
import { Outlet } from "react-router-dom";
import { useWindowSize } from "react-use";
import ScrollToTop from "routers/ScrollToTop";
import Footer from "shared/Footer/Footer";
import LoginModal from "components/Modals/LoginModal";
import RegisterModal from "components/Modals/RegisterModal";
import PropertyPostModal from "components/Modals/PropertyPostModal";
import PropertyEditModal from "components/Modals/PropertyEditModal";
import AccountMobileModal from "components/Modals/AccountMobileModal";

const MainLayout = () => {
  let WIN_WIDTH = useWindowSize().width;
  if (typeof window !== "undefined") {
    WIN_WIDTH = WIN_WIDTH || window.innerWidth;
  }
  return (
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
      <ScrollToTop />
      <SiteHeader />
      <ToasterProvider />
      <PropertyPostModal />
      <PropertyEditModal />
      <LoginModal />
      <AccountMobileModal />
      <RegisterModal />
      <Outlet />

      {WIN_WIDTH < 768 && <FooterNav />}
      <Footer />
    </div>
  );
};

export default MainLayout;
