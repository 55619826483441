import axios, { AxiosResponse } from "axios";
import { Reservation } from "containers/ListingDetailPage/listing-stay-detail/StayDatesRangeInput";

export default async function getReservationsForListing(
  id: string | number
): Promise<Reservation[] | null> {
  try {
    const response: AxiosResponse<Reservation[]> = await axios.get(`/listing/reservations/${id}`, {
      headers: { Authorization: localStorage.getItem("token") },
    });

    const reservationData = response.data;
    if (reservationData[0]?.id <= 0) {
      return null;
    }
    return reservationData;
  } catch (error: any) {
    throw new Error(error);
  }
}
