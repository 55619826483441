import { User } from "Types/User";
import axios, { AxiosResponse } from "axios";

export default async function getUser(id: string | number): Promise<User | null> {
  try {
    const response: AxiosResponse<User> = await axios.get(`/users/getUser/${id}`, {
      headers: { Authorization: localStorage.getItem("token") },
    });
    //@ts-ignore
    const userData = response.data.user;

    if (userData.id <= 0) {
      return null;
    }
    return userData;
  } catch (error: any) {
    throw new Error(error);
  }
}
