import React, { FC, useEffect } from "react";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import { Helmet } from "react-helmet";
import SectionHero2ArchivePage from "components/SectionHero2ArchivePage/SectionHero2ArchivePage";
import SectionGridHasMap from "containers/ListingRealEstatePage/SectionGridHasMap";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import { useSearchParams } from "react-router-dom";

export interface ListingMapPageProps {
  className?: string;
}

const ListingMap: FC<ListingMapPageProps> = ({ className = "" }) => {
  const [searchParams] = useSearchParams();
  const adultGuests = Number(searchParams.get("adultGuests"));
  const childrenGuests = Number(searchParams.get("childrenGuests"));
  const infantGuests = Number(searchParams.get("infantGuests"));
  const country = searchParams.get("location") || "";
  const state = searchParams.get("state") || "";
  const startDate = new Date(searchParams.get("startDate") || new Date());
  const endDate = new Date(searchParams.get("endDate") || new Date());
  return (
    <div className={`nc-ListingStayMapPage relative ${className}`} data-nc-id="ListingStayMapPage">
      <Helmet>
        <title>
          {/* Listings || {searchParams.get("location")}, {searchParams.get("selectedState")} */}
        </title>
      </Helmet>
      <BgGlassmorphism />

      {/* SECTION HERO */}
      <div className="container pt-10 pb-24 lg:pt-16 lg:pb-28">
        <SectionHeroArchivePage currentPage="Stays" currentTab="Stays" />
      </div>

      {/* SECTION */}
      <div className="container pb-24 lg:pb-28 2xl:pl-10 xl:pr-0 xl:max-w-none">
        <SectionGridHasMap
          adultGuests={adultGuests}
          childrenGuests={childrenGuests}
          infantGuests={infantGuests}
          country={country}
          state={state}
          startDate={startDate}
          endDate={endDate}
        />
      </div>
    </div>
  );
};

export default ListingMap;
