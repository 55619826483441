import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import RealEstateSearchForm from "./RealEstateSearchForm";
import RealEstateSearchFormListings from "./RealEstateSearchFormListings";

interface HeroRealEstateSearchFormListingsProps {
  className?: string;
}

const HeroRealEstateSearchFormListings: FC<HeroRealEstateSearchFormListingsProps> = ({
  className = "",
}) => {
  return (
    <div className={`nc-HeroRealEstateSearchForm w-full max-w-6xl py-5 lg:py-0 ${className}`}>
      <RealEstateSearchFormListings />
    </div>
  );
};

export default HeroRealEstateSearchFormListings;
