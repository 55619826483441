import React, { FC, MouseEvent } from "react";

interface ButtonProps {
  label: string;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  blurred?: boolean;
  disabled?: boolean;
  outline?: boolean;
  danger?: boolean;
  small?: boolean;
  moreVisible?: boolean;
  icon?: FC<{ size: number; className: string }>;
}

const ModalButton: FC<ButtonProps> = ({
  label,
  onClick,
  blurred = false,
  disabled = false,
  outline = false,
  danger = false,
  small = false,
  moreVisible = false,
  icon: Icon,
}) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled || blurred}
      className={`
        relative
        disabled:opacity-50
        disabled:cursor-not-allowed
        rounded-lg
        border-[#1f4c6f]
        md:hover:opacity-80
        transition
        w-full
        ${moreVisible ? "bg-green-600" : "bg-primary"}
        ${moreVisible ? "border-none" : "border-primary"}
        ${danger ? "bg-red-500 border-none" : "bg-primary"}
        ${outline ? "bg-white" : "bg-primary"}
        ${outline ? "border-black" : "border-primary"}
        ${outline ? "text-black" : "text-black"}
        ${small ? "py-1" : "py-3"}
        ${small ? "text-sm" : "text-md"}
        ${small ? "font-light" : "font-semibold"}
        ${small ? "border-[1px]" : "border-2"}
        ${blurred ? "bg-opacity-50" : ""}
      `}
    >
      {Icon && (
        <Icon
          size={24}
          className="
            absolute
            left-4
            top-3
          "
        />
      )}
      {label}
    </button>
  );
};

export default ModalButton;
