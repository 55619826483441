import create from "zustand";

interface AccountMobileModalState {
  isOpen: boolean;
}

interface AccountMobileModalActions {
  onOpen: () => void;
  onClose: () => void;
}

const useAccountMobileModal = create<AccountMobileModalState & AccountMobileModalActions>(
  (set) => ({
    isOpen: false,
    onOpen: () => set({ isOpen: true }),
    onClose: () => set({ isOpen: false }),
  })
);

export default useAccountMobileModal;
