import { Country as CSCCountry, State } from "country-state-city";

export interface Country {
  value: string;
  label: string;
  latlang: [number, number];
  states: any[];
}
export interface State {
  name: string;
  countryCode: string;
  latlang: [number, number];
}

const countries: Country[] = CSCCountry.getAllCountries().map((country: any) => ({
  value: country.isoCode,
  label: country.name,
  latlang: [country.latitude, country.longitude],
  states: State.getStatesOfCountry(country.isoCode),
}));

const useCountries = () => {
  const getAll = (): Country[] => countries;
  const getByValue = (value: string): Country | undefined => {
    return countries.find((item: any) => item.value === value);
  };
  const getStatesByCountry = (countryIsoCode: string): any[] => {
    const selectedCountry = countries.find((country: Country) => country.label === countryIsoCode);
    return selectedCountry ? selectedCountry.states : [];
  };
  return {
    getAll,
    getByValue,
    getStatesByCountry,
  };
};

export default useCountries;
