import React, { useCallback, useState } from "react";
import { useForm, SubmitHandler, SubmitErrorHandler } from "react-hook-form";
import Modal from "./Modal";
import axios from "axios";
import useRegisterModal from "../../hooks/useRegisterModal";
import Heading from "../Heading";
import toast from "react-hot-toast";
import useLoginModal from "../../hooks/useLoginModal";
import Input from "components/Inputs/Input";
import useAccountMobileModal from "hooks/useAccountMobileModal";
import ModalButton from "components/ModalButton";
import { useNavigate } from "react-router-dom";

const AccountMobileModal: React.FC = () => {
  const navgiate = useNavigate();
  const signUserOut = () => {
    axios
      .post("/auth/logout", null, {
        headers: { Authorization: localStorage.getItem("token") },
      })
      .then((res) => {
        toast.success("You have successfully signed out of your account.");
        setTimeout(() => {
          localStorage.removeItem("token");
          window.location.reload();
        }, 400);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong. Please reload the page.");
      });
  };
  const accountModal = useAccountMobileModal();
  const bodyContent = (
    <div className="flex flex-col gap-4">
      <Heading title="Account actions!" subtitle="What do you want to do?" />
      <ModalButton
        outline
        disabled={false}
        label={"View profile"}
        onClick={() => {
          navgiate("/account");
          accountModal.onClose();
        }}
      />
      {/* <ModalButton outline disabled={false} label={"My Listings"} onClick={() => {}} /> */}
      {/* <ModalButton outline disabled={false} label={"My bookings"} onClick={() => {}} /> */}
      {/* <ModalButton outline disabled={false} label={"View Wallet"} onClick={() => {}} /> */}
      {/* <ModalButton outline disabled={false} label={"Add credits"} onClick={() => {}} /> */}
      <ModalButton
        outline
        disabled={false}
        label={"Log out"}
        onClick={() => {
          signUserOut();
          accountModal.onClose();
          navgiate("/");
        }}
      />
    </div>
  );

  const footerContent = (
    <div className="flex flex-col gap-4 mt-3 ">
      <hr />
    </div>
  );

  return (
    <Modal
      disabled={false}
      isOpen={accountModal.isOpen}
      title="Account actions"
      onClose={accountModal.onClose}
      body={bodyContent}
      footer={footerContent}
    />
  );
};

export default AccountMobileModal;
