import DatePicker from "react-datepicker";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import React, { FC, Fragment, useEffect, useState } from "react";
import DatePickerCustomHeaderTwoMonth from "./DatePickerCustomHeaderTwoMonth";
import DatePickerCustomDay from "./DatePickerCustomDay";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import StayDatesRangeInput, {
  Reservation,
} from "containers/ListingDetailPage/listing-stay-detail/StayDatesRangeInput";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Listing } from "./Modals/PropertyPostModal";
import GuestsInput from "containers/ListingDetailPage/listing-stay-detail/GuestsInput";

interface ModalSelectDateProps {
  renderChildren?: (p: { openModal: () => void }) => React.ReactNode;
  reservations: Reservation[];
  listing: Listing;
}

const ModalSelectDate: FC<ModalSelectDateProps> = ({ renderChildren, reservations, listing }) => {
  const [showModal, setShowModal] = useState(false);
  const [queryParams] = useSearchParams();
  const startDateParam = queryParams.get("checkIn");
  const endDateParam = queryParams.get("checkOut");
  const adultGuestParam = queryParams.get("adultGuestCount");
  const childrenGuestsParam = queryParams.get("childrenGuestCount");
  const infantGuestsParam = queryParams.get("infantGuestCount");
  const [adultGuestCount, setAdultGuestCount] = useState<number>(Number(adultGuestParam));
  const [childrenGuestCount, setChildrenGuestCount] = useState<number>(Number(childrenGuestsParam));
  const [infantGuestCount, setInfantGuestCount] = useState<number>(Number(infantGuestsParam));
  const [numberOfNights, setNumberOfNights] = useState<number>(0);
  const onChangeDate = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  function closeModal() {
    setShowModal(false);
  }

  function openModal() {
    setShowModal(true);
  }
  const [startDateBooking, setStartDate] = useState<Date | null>(
    new Date(formatDate(new Date(startDateParam || Date.now())))
  );
  const [endDateBooking, setEndDate] = useState<Date | null>(
    new Date(formatDate(new Date(endDateParam || Date.now())))
  );
  const [url, setUrl] = useState("");
  const renderButtonOpenModal = () => {
    return renderChildren ? (
      renderChildren({ openModal })
    ) : (
      <button onClick={openModal}>Select Date</button>
    );
  };
  function formatDate(date: Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  const router = useNavigate();
  const [searchParams] = useSearchParams();
  const updateURL = async () => {
    const currentQuery = Object.fromEntries(searchParams);
    const updatedQuery = {
      ...currentQuery,
      checkIn: formatDate(new Date(startDateBooking || Date.now())) || "",
      checkOut: formatDate(new Date(endDateBooking || Date.now())) || "",
      adultGuestCount: adultGuestCount.toString(),
      childrenGuestCount: childrenGuestCount.toString(),
      infantGuestCount: infantGuestCount.toString(),
    };

    const paramsString = new URLSearchParams(updatedQuery).toString();
    const url = paramsString ? `?${paramsString}` : "/";
    router(url);
    setUrl(url);
  };
  useEffect(() => {
    updateURL();
  }, [startDateBooking, endDateBooking]);
  const handleNumberOfNightsChange = (
    newNumberOfNights: number,
    startDate: Date | null,
    endDate: Date | null
  ) => {
    setNumberOfNights(newNumberOfNights);
    setStartDate(startDate);
    setEndDate(endDate);
  };
  const handleChangeOfGuests = (
    totalGuests: number,
    adultGuests: number,
    childrenGuests: number,
    infantGuests: number
  ) => {
    setAdultGuestCount(adultGuests);
    setChildrenGuestCount(childrenGuests);
    setInfantGuestCount(infantGuests);
  };
  return (
    <>
      {renderButtonOpenModal()}
      <Transition appear show={showModal} as={Fragment}>
        <Dialog
          as="div"
          className="HeroSearchFormMobile__Dialog relative z-50"
          onClose={closeModal}
        >
          <div className="fixed inset-0 bg-neutral-100 dark:bg-neutral-900">
            <div className="flex h-full">
              <Transition.Child
                as={Fragment}
                enter="ease-out transition-transform"
                enterFrom="opacity-0 translate-y-52"
                enterTo="opacity-100 translate-y-0"
                leave="ease-in transition-transform"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-52"
              >
                <Dialog.Panel className="relative h-full overflow-hidden flex-1 flex flex-col justify-between ">
                  <>
                    <div className="absolute left-4 top-4">
                      <button className="focus:outline-none focus:ring-0" onClick={closeModal}>
                        <XMarkIcon className="w-5 h-5 text-black dark:text-white" />
                      </button>
                    </div>

                    <div className="flex-1 pt-12 p-1 flex flex-col overflow-auto">
                      <div className="flex-1 flex flex-col bg-white dark:bg-neutral-800">
                        <div className="flex-1 flex flex-col transition-opacity animate-[myblur_0.4s_ease-in-out] overflow-auto">
                          <div className="p-5">
                            <span className="block font-semibold text-xl sm:text-2xl">
                              {`When do you plan to try the property?`}
                            </span>
                          </div>
                          <div className="flex-1 relative flex z-10">
                            <div className="overflow-hidden w-full rounded-3xl">
                              {" "}
                              <StayDatesRangeInput
                                monthsShown={1}
                                onNumberOfNightsChange={handleNumberOfNightsChange}
                                reservations={reservations}
                                checkIn={
                                  new Date(formatDate(new Date(startDateParam || Date.now())))
                                }
                                checkOut={
                                  new Date(formatDate(new Date(endDateParam || Date.now())))
                                }
                                className="flex-1 z-[11] w-full"
                              />
                              <GuestsInput
                                className="flex-1"
                                handleChangeOfGuests={handleChangeOfGuests}
                                adultGuestParam={Number(adultGuestParam)}
                                childrenGuestsParam={Number(childrenGuestsParam)}
                                infantGuestsParam={Number(infantGuestsParam)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="px-4 py-3 bg-white dark:bg-neutral-900 border-t border-neutral-200 dark:border-neutral-700 flex justify-between">
                      <button
                        type="button"
                        className="underline font-semibold flex-shrink-0"
                        onClick={() => {
                          onChangeDate([null, null]);
                        }}
                      >
                        Clear dates
                      </button>
                      <ButtonPrimary
                        sizeClass="px-6 py-3 !rounded-xl"
                        onClick={() => {
                          closeModal();
                        }}
                      >
                        Save
                      </ButtonPrimary>
                    </div>
                  </>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ModalSelectDate;
