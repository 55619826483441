"use client";

import { ClockIcon, MapPinIcon } from "@heroicons/react/24/outline";
import React, { useState, useRef, useEffect, FC, useCallback } from "react";
import ClearDataButton from "./ClearDataButton";
import useCountries, { Country, State } from "hooks/useCountries";
import { MapIcon } from "@heroicons/react/24/solid";
import { useNavigate, useSearchParams } from "react-router-dom";

export interface LocationInputProps {
  placeHolder?: string;
  desc?: string;
  className?: string;
  divHideVerticalLineClass?: string;
  autoFocus?: boolean;
  onLocationChange: (location: string, state: string) => void;
}

const LocationInputListings: FC<LocationInputProps> = ({
  autoFocus = false,
  placeHolder = "Where",
  desc = "Search destinations?",
  className = "nc-flex-1.5",
  divHideVerticalLineClass = "left-10 -right-0.5",
  onLocationChange,
}) => {
  const { getAll, getStatesByCountry } = useCountries();
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const router = useNavigate();
  const [searchParams] = useSearchParams();
  const [value, setValue] = useState("");
  const [showPopover, setShowPopover] = useState(autoFocus);
  const [countries, setCountries] = useState(getAll());
  const [location, setSelectedLocation] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [states, setStates] = useState([]);
  const [showLocation, setShowLocation] = useState("");
  const [latLng, setLatLng] = useState([]);
  useEffect(() => {
    //@ts-ignore
    setStates(getStatesByCountry(location));
  }, [location]);
  useEffect(() => {
    handleSelectLocation(searchParams.get("location") || "", [
      searchParams.get("lat"),
      searchParams.get("lng "),
    ]);
    handleSelectState(
      searchParams.get("state") || "",
      //@ts-ignore
      searchParams.get("state") ? `${[searchParams.get("lat"), searchParams.get("lng ")]}` : null
    );
  }, []);

  useEffect(() => {
    setShowPopover(autoFocus);
  }, [autoFocus]);

  useEffect(() => {
    if (eventClickOutsideDiv) {
      document.removeEventListener("click", eventClickOutsideDiv);
    }
    showPopover && document.addEventListener("click", eventClickOutsideDiv);
    return () => {
      document.removeEventListener("click", eventClickOutsideDiv);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPopover]);

  useEffect(() => {
    if (showPopover && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showPopover]);

  const eventClickOutsideDiv = (event: MouseEvent) => {
    if (!containerRef.current) return;
    if (!showPopover || containerRef.current.contains(event.target as Node)) {
      return;
    }
    setShowPopover(false);
  };

  const handleSelectLocation = (item: string, latLang: any) => {
    setValue("");
    setLatLng(latLang);
    setSelectedLocation(item);
    setShowLocation(`${item}, `);
    setShowPopover(false);
  };
  useEffect(() => {
    if (location.length === 0 && searchParams.get("location")) {
      const locationFromUrl = searchParams.get("location") || "";
      const stateFromUrl = searchParams.get("state") || "";
      setShowLocation(`${locationFromUrl}, ${stateFromUrl}`);
      setSelectedLocation(locationFromUrl);
    }
  }, []);
  const handleSelectState = (item: string, latLang: any) => {
    const newShowLocation = `${location}, ${item}`;

    setShowLocation(newShowLocation);
    if (item) {
      setLatLng(latLang);
    }
    setSelectedState(item);
    setShowPopover(false);
  };
  useEffect(() => {
    onLocationChange(location, selectedState);
  }, [location, selectedState, onLocationChange]);

  const updateURL = async () => {
    const currentQuery = Object.fromEntries(searchParams);
    const updatedQuery = {
      ...currentQuery,
      state: selectedState,
      location,
      lat: latLng[0],
      lng: latLng[1],
    };

    const paramsString = new URLSearchParams(updatedQuery).toString();
    const url = paramsString ? `${window.location.pathname}?${paramsString}` : "/";
    router(url);
  };

  const renderRecentSearches = () => {
    return (
      <>
        <h3 className="block mt-2 sm:mt-0 px-4 sm:px-8 font-semibold text-base sm:text-lg text-neutral-800 dark:text-neutral-100">
          Select
        </h3>
        <div className="mt-2">
          {location.length < 1
            ? countries
                .filter((state) => state.label.toLowerCase().includes(value.toLowerCase()))
                .map((item: Country) => (
                  <span
                    onClick={() => handleSelectLocation(item.label, item.latlang)}
                    key={item.label}
                    className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
                  >
                    <span className="block text-neutral-400">
                      <MapIcon className="h-4 w-4 sm:h-6 sm:w-6" />
                    </span>
                    <span className="block font-medium text-neutral-700 dark:text-neutral-200">
                      {item.label}
                    </span>
                  </span>
                ))
            : states
                ?.filter((state: State) => state.name.toLowerCase().includes(value.toLowerCase()))
                .map((item: State) => (
                  <span
                    onClick={() => {
                      //@ts-ignore
                      handleSelectState(item.name, [item.latitude, item.longitude]);
                    }}
                    key={item.name}
                    className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
                  >
                    <span className="block text-neutral-400">
                      <MapIcon className="h-4 w-4 sm:h-6 sm:w-6" />
                    </span>
                    <span className="block font-medium text-neutral-700 dark:text-neutral-200">
                      {item.name}
                    </span>
                  </span>
                ))}
        </div>
      </>
    );
  };
  const renderSearchValue = () => {
    return (
      <>
        {location.length < 1
          ? countries
              .filter((state) => state.label.toLowerCase().includes(value.toLowerCase()))
              .map((item: Country) => (
                <span
                  onClick={() => handleSelectLocation(item.label, item.latlang)}
                  key={item.label}
                  className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
                >
                  <span className="block text-neutral-400">
                    <MapIcon className="h-4 w-4 sm:h-6 sm:w-6" />
                  </span>
                  <span className="block font-medium text-neutral-700 dark:text-neutral-200">
                    {item.label}
                  </span>
                </span>
              ))
          : states
              ?.filter((state: State) => state.name.toLowerCase().includes(value.toLowerCase()))
              .map((item: State) => (
                <span
                  onClick={() => {
                    //@ts-ignore
                    handleSelectState(item.name, [item.latitude, item.longitude]);
                  }}
                  key={item.name}
                  className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
                >
                  <span className="block text-neutral-400">
                    <MapIcon className="h-4 w-4 sm:h-6 sm:w-6" />
                  </span>
                  <span className="block font-medium text-neutral-700 dark:text-neutral-200">
                    {item.name}
                  </span>
                </span>
              ))}
      </>
    );
  };

  return (
    <div className={`relative flex ${className}`} ref={containerRef}>
      <div
        onClick={() => setShowPopover(true)}
        className={`flex z-10 flex-1 relative [ nc-hero-field-padding ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left  ${
          showPopover ? "nc-hero-field-focused" : ""
        }`}
      >
        <div className="text-neutral-300 dark:text-neutral-400">
          <MapPinIcon className="w-5 h-5 lg:w-7 lg:h-7" />
        </div>
        <div className="flex-grow">
          <input
            className={`block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-300 xl:text-lg font-semibold placeholder-neutral-800 dark:placeholder-neutral-200 truncate`}
            placeholder={
              location.length < 1
                ? "Select country"
                : selectedState.length < 1
                ? "Select city"
                : showLocation
            }
            value={value}
            autoFocus={showPopover}
            onChange={(e) => {
              setValue(e.currentTarget.value);
            }}
            ref={inputRef}
          />
          <span className="block mt-0.5 text-sm text-neutral-400 font-light ">
            <span className="line-clamp-1">{!!location ? showLocation : placeHolder}</span>
          </span>
          {location && showPopover && (
            <ClearDataButton
              onClick={() => {
                setValue("");
                if (selectedState) {
                  setSelectedState("");
                } else {
                  setSelectedState("");
                  setSelectedLocation("");
                }
                setLatLng([]);
              }}
            />
          )}
        </div>
      </div>

      {showPopover && (
        <div
          className={`h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 bg-white dark:bg-neutral-800 ${divHideVerticalLineClass}`}
        ></div>
      )}

      {showPopover && (
        <div className="absolute left-0 z-40 w-full min-w-[300px] sm:min-w-[500px] bg-white dark:bg-neutral-800 top-full mt-3 py-3 sm:py-6 rounded-3xl shadow-xl max-h-96 overflow-y-auto">
          {value ? renderSearchValue() : renderRecentSearches()}
        </div>
      )}
    </div>
  );
};

export default LocationInputListings;
