import React, { Fragment, FC, useEffect } from "react";
import { Popover, Transition } from "@headlessui/react";
import Slider from "rc-slider";
import ButtonSubmit from "../ButtonSubmit";
import { ArrowsPointingOutIcon } from "@heroicons/react/24/outline";
import convertNumbThousand from "utils/convertNumbThousand";
import { useSearchParams, useNavigate } from "react-router-dom";

export interface SizeRangeInputProps {
  rangeSizes: [number, number];
  setRangeSizes: React.Dispatch<React.SetStateAction<[number, number]>>;
  fieldClassName?: string;
}

const SizeRangeInput: FC<SizeRangeInputProps> = ({
  rangeSizes,
  setRangeSizes,
  fieldClassName = "[ nc-hero-field-padding ]",
}) => {
  const [searchParams] = useSearchParams();
  const minSize = searchParams.get("minSize");
  const maxSize = searchParams.get("maxSize");

  useEffect(() => {
    setRangeSizes([
      Number(Number(minSize) === 0 ? 0 : minSize),
      Number(Number(maxSize) === 0 ? 1000 : maxSize),
    ]);
  }, []);

  const router = useNavigate();
  const updateURL = async () => {
    const currentQuery = Object.fromEntries(searchParams);
    const updatedQuery = {
      ...currentQuery,
      minPrice: rangeSizes[0].toString(),
      maxPrice: rangeSizes[1].toString(),
    };

    const paramsString = new URLSearchParams(updatedQuery).toString();
    const url = paramsString ? `${window.location.pathname}?${paramsString}` : "/";
    router(url);
  };

  return (
    <Popover className="flex relative flex-[1.3]">
      {({ open }) => (
        <>
          <div
            className={`flex-1 z-10 flex items-center focus:outline-none cursor-pointer ${
              open ? "nc-hero-field-focused" : ""
            }`}
          >
            <Popover.Button
              className={`flex-1 flex text-left items-center focus:outline-none ${fieldClassName} space-x-3 `}
            >
              <div className="text-neutral-300 dark:text-neutral-400">
                <ArrowsPointingOutIcon className="w-5 h-5 lg:w-7 lg:h-7" />
              </div>
              <div className="flex-grow">
                <span className="block xl:text-lg font-semibold truncate">{`${convertNumbThousand(
                  rangeSizes[0]
                )} sq. - ${convertNumbThousand(rangeSizes[1])} sq.`}</span>
                <span className="block mt-1 text-sm text-neutral-400 leading-none font-light ">
                  Size range
                </span>
              </div>
            </Popover.Button>

            {/* BUTTON SUBMIT OF FORM */}
          </div>

          {open && (
            <div className="h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 -left-0.5 right-1 bg-white dark:bg-neutral-800"></div>
          )}

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute left-0 lg:right-0 z-10 w-full sm:min-w-[340px] max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl">
              <div className="relative flex flex-col space-y-8">
                <div className="space-y-5">
                  <span className="font-medium">Range Size</span>
                  <Slider
                    range
                    className="text-red-400"
                    min={10}
                    max={1000}
                    defaultValue={rangeSizes}
                    allowCross={false}
                    step={10}
                    //@ts-ignore
                    onChange={(value) => setRangeSizes(value)}
                  />
                </div>

                <div className="flex justify-between space-x-3">
                  <div>
                    <label
                      htmlFor="minSize"
                      className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                    >
                      Min size
                    </label>
                    <div className="mt-1 relative rounded-md">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <span className="text-neutral-500 sm:text-sm"> sq.</span>
                      </div>
                      <input
                        type="text"
                        disabled
                        name="minSize"
                        id="minSize"
                        className="ml-2 focus:ring-primary-500 focus:border-primary-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 dark:border-neutral-500 rounded-full text-neutral-900 dark:text-neutral-200 bg-transparent"
                        value={convertNumbThousand(rangeSizes[0])}
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="maxSize"
                      className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                    >
                      Max size
                    </label>
                    <div className="mt-1 relative rounded-md">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none mr-10">
                        <span className="text-neutral-500 sm:text-sm">sq.</span>
                      </div>
                      <input
                        type="text"
                        disabled
                        name="maxSize"
                        id="maxSize"
                        className="ml-2 focus:ring-primary-500 focus:border-priring-primary-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 dark:border-neutral-500 rounded-full text-neutral-900 dark:text-neutral-200 bg-transparent"
                        value={convertNumbThousand(rangeSizes[1])}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default SizeRangeInput;
