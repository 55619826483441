import create from "zustand";

interface PropertyEditModalState {
  isOpen: boolean;
  listingId: number | null;
}

interface PropertyEditModalActions {
  onOpen: () => void;
  onClose: () => void;
  setListingId: (id: number) => void;
}

const usePropertyEditModal = create<PropertyEditModalState & PropertyEditModalActions>((set) => ({
  isOpen: false,
  listingId: null,
  setListingId: (id: number) => set({ listingId: id }),
  onOpen: () => set({ isOpen: true }),
  onClose: () => set({ isOpen: false }),
}));

export default usePropertyEditModal;
