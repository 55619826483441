import axios, { AxiosResponse } from "axios";
import { Reservation } from "containers/ListingDetailPage/listing-stay-detail/StayDatesRangeInput";

export default async function getReservationForListingForUser(
  id: string | number,
  checkIn: string,
  checkOut: string
): Promise<Reservation | null> {
  try {
    const response: AxiosResponse<Reservation> = await axios.post(
      `/listing/reservations/get`,
      { checkIn, checkOut, id },
      {
        headers: { Authorization: localStorage.getItem("token") },
      }
    );

    const reservationData = response.data;
    if (reservationData?.id <= 0) {
      return null;
    }
    return reservationData;
  } catch (error: any) {
    throw new Error(error);
  }
}
