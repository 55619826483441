import React, { FC } from "react";

interface Props {
  dayOfMonth: number;
  date?: Date | undefined;
  isExcluded?: boolean;
}

const DatePickerCustomDay: FC<Props> = ({ dayOfMonth, date, isExcluded }) => {
  const dayStyle: React.CSSProperties = {
    position: "relative",
    display: "inline-block",
    padding: "5px",
    marginRight: "5px",
  };
  const overlayStyle: React.CSSProperties = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    color: "black",
    height: "100%",
    zIndex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  return (
    <div style={dayStyle}>
      {isExcluded ? (
        <>
          <span className="react-datepicker__day_span">{dayOfMonth}</span>
          <div style={overlayStyle}>-</div>
        </>
      ) : (
        <span className="react-datepicker__day_span">{dayOfMonth}</span>
      )}
    </div>
  );
};

export default DatePickerCustomDay;
