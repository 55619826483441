import React, { useState, useEffect } from "react";
import converSelectedDateToString from "utils/converSelectedDateToString";
import PriceRangeInput from "components/HeroSearchForm/(real-estate-search-form)/PriceRangeInput";
import LocationInputListings from "components/HeroSearchForm/LocationInputListings";
import LocationInput from "components/HeroSearchForm/LocationInput";
import { useSearchParams } from "react-router-dom";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SizeRangeInput from "components/HeroSearchForm/(real-estate-search-form)/SizeRangeInput";

const StaySearchForm = () => {
  const [locationInputTo, setLocationInputTo] = useState("");
  const [rangePrices, setRangePrices] = useState<[number, number]>([10000, 10000000]);
  const [rangeSizes, setRangeSizes] = useState<[number, number]>([0, 1000]);

  const [searchParams] = useSearchParams();
  useEffect(() => {
    const location = searchParams.get("location") || "";
    setLocationInputTo(location);
    const minPrice = parseInt(searchParams.get("minPrice") || "10000", 10);
    const maxPrice = parseInt(searchParams.get("maxPrice") || "10000000", 10);
    setRangePrices([minPrice, maxPrice]);
  }, []);

  return (
    <form className="w-full space-y-5">
      <div className="border border-gray-300 rounded-md p-4">
        <label htmlFor="location" className="block text-lg font-medium text-gray-700 mb-2">
          Location
        </label>
        <LocationInput />
      </div>

      <div className="border border-gray-300 rounded-md p-4">
        <label htmlFor="priceRange" className="block text-lg font-medium text-gray-700 mb-2">
          Price Range
        </label>
        <PriceRangeInput rangePrices={rangePrices} setRangePrices={setRangePrices} disableSearch />
      </div>
      <div className="border border-gray-300 rounded-md p-4">
        <label htmlFor="priceRange" className="block text-lg font-medium text-gray-700 mb-2">
          Size Range
        </label>
        <SizeRangeInput rangeSizes={rangeSizes} setRangeSizes={setRangeSizes} />
      </div>
    </form>
  );
};

export default StaySearchForm;
