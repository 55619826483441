import { Tab } from "@headlessui/react";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import React, { FC, Fragment, useCallback, useContext, useEffect, useState } from "react";
import visaPng from "images/vis.png";
import mastercardPng from "images/mastercard.svg";
import { GuestsObject } from "components/HeroSearchForm/type";
import StartRating from "components/StartRating/StartRating";
import NcModal from "shared/NcModal/NcModal";
import ModalSelectDate from "components/ModalSelectDate";
import converSelectedDateToString from "utils/converSelectedDateToString";
import ModalSelectGuests from "components/ModalSelectGuests";
import Label from "components/Label/Label";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Button from "shared/Button/Button";
import { IoCaretBackCircleOutline } from "react-icons/io5";
import { formatPrice } from "./ListingStayDetailPage";
import toast from "react-hot-toast";
import axios, { AxiosError, AxiosResponse } from "axios";
import { useForm } from "react-hook-form";
import Input from "components/Inputs/Input";
import { UserContext } from "UserContext";
import useLoginModal from "hooks/useLoginModal";
export interface CheckOutPagePageMainProps {
  className?: string;
}
interface BookingData {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  checkIn: string;
  checkOut: string;
  adultGuestCount: string;
  childrenGuestCount: string;
  infantGuestCount: string;
  listingId: string;
  pricePerNight: string;
}
interface ErrBookingData {
  email?: {
    type: string;
    message: string;
  };
  firstName?: {
    type: string;
    message: string;
  };
  lastName?: {
    type: string;
    message: string;
  };
  phoneNumber?: {
    type: string;
    message: string;
  };
}
const CheckOutPagePageMain: FC<CheckOutPagePageMainProps> = ({ className = "" }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isAuthenticated } = useContext(UserContext);
  const [errs, setErrs] = useState<ErrBookingData>({});
  const loginModal = useLoginModal();
  const { listingId } = useParams();

  const handleGoBack = () => {
    navigate(-1);
  };
  const queryParams = new URLSearchParams(location.search);
  const startDateParam = queryParams.get("checkIn");
  const endDateParam = queryParams.get("checkOut");
  const adultGuestParam = queryParams.get("adultGuestCount");
  const childrenGuestsParam = queryParams.get("childrenGuestCount");
  const infantGuestsParam = queryParams.get("infantGuestCount");
  const amountOfNightsParam = queryParams.get("totalNights");
  const pricePerNightParam = queryParams.get("pricePerNight");
  const [startDate] = useState<Date | null>(new Date(startDateParam || Date.now()));
  const [endDate] = useState<Date | null>(new Date(endDateParam || Date.now()));

  const [guests] = useState<GuestsObject>({
    guestAdults: Number(adultGuestParam),
    guestChildren: Number(childrenGuestsParam),
    guestInfants: Number(infantGuestsParam),
  });

  const renderSidebar = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
        <div className="flex flex-col space-y-4">
          <h3 className="text-2xl font-semibold">Price details</h3>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>
              {formatPrice(Number(pricePerNightParam))} € x {amountOfNightsParam}{" "}
              {Number(amountOfNightsParam) > 1 ? "nights" : "night"}
            </span>
            <span>{formatPrice(Number(pricePerNightParam) * Number(amountOfNightsParam))} €</span>
          </div>

          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>Total</span>
            <span>{formatPrice(Number(pricePerNightParam) * Number(amountOfNightsParam))} €</span>
          </div>
        </div>
      </div>
    );
  };
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    setCustomValue("checkIn", startDateParam);
    setCustomValue("checkOut", endDateParam);
    setCustomValue("adultGuestCount", adultGuestParam);
    setCustomValue("childrenGuestCount", childrenGuestsParam);
    setCustomValue("infantGuestCount", infantGuestsParam);
    setCustomValue("listingId", listingId);
    setCustomValue("pricePerNight", pricePerNightParam);
  }, [
    startDateParam,
    endDateParam,
    adultGuestParam,
    childrenGuestsParam,
    infantGuestsParam,
    pricePerNightParam,
  ]);
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm<BookingData>({
    defaultValues: {
      email: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      checkIn: "",
      checkOut: "",
      adultGuestCount: "",
      childrenGuestCount: "",
      infantGuestCount: "",
      pricePerNight: "",
      listingId: listingId,
    },
  });
  const setCustomValue = (id: any, value: any) => {
    setValue(id, value, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
  };
  const email = watch("email");
  const firstName = watch("firstName");
  const lastName = watch("lastName");
  const phoneNumber = watch("phoneNumber");
  const checkIn = watch("checkIn");
  const checkOut = watch("checkOut");
  const adultGuestCount = watch("adultGuestCount");
  const childrenGuestCount = watch("childrenGuestCount");
  const infantGuestCount = watch("infantGuestCount");
  const listingIdValue = watch("listingId");
  const pricePerNight = watch("pricePerNight");

  const onSubmit = async () => {
    const errorsCopy = { ...errs };

    if (!isAuthenticated) {
      loginModal.onOpen();
    }

    if (!email || !email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) {
      errorsCopy.email = {
        type: "required",
        message: "Email is required",
      };
    } else {
      delete errorsCopy.email;
    }

    if (!firstName) {
      errorsCopy.firstName = {
        type: "required",
        message: "First Name is required",
      };
    } else {
      delete errorsCopy.firstName;
    }

    if (!lastName) {
      errorsCopy.lastName = {
        type: "required",
        message: "Last Name is required",
      };
    } else {
      delete errorsCopy.lastName;
    }

    if (!phoneNumber) {
      errorsCopy.phoneNumber = {
        type: "required",
        message: "Phone Number is required",
      };
    } else {
      delete errorsCopy.phoneNumber;
    }

    setErrs(errorsCopy);
    const formData = new FormData();
    formData.append("email", email);
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("phoneNumber", phoneNumber);
    formData.append("checkIn", checkIn);
    formData.append("checkOut", checkOut);
    formData.append("adultGuestCount", adultGuestCount);
    formData.append("childrenGuestCount", childrenGuestCount);
    formData.append("infantGuestCount", infantGuestCount);
    formData.append("listingId", listingIdValue);
    formData.append("pricePerNight", pricePerNight);
    if (
      email &&
      email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/) &&
      firstName &&
      lastName &&
      phoneNumber &&
      checkIn &&
      checkOut &&
      adultGuestCount &&
      childrenGuestCount &&
      infantGuestCount &&
      listingIdValue
    ) {
      setIsLoading(true);

      try {
        const res: AxiosResponse = await axios.post("/listing/reservations/make", formData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token") || "",
          },
        });

        toast.promise(
          Promise.resolve(res),
          {
            loading: "Creating...",
            success: <b>Listing successfully booked!</b>,
            error: <b>Something went wrong.</b>,
          },
          { duration: 6000 }
        );

        reset();
        navigate(
          `/book-listing/${listingId}/confirmation?checkIn=${checkIn}&checkOut=${checkOut}&adultGuestCount=${adultGuestParam}&childrenGuestCount=${childrenGuestsParam}&infantGuestCount=${infantGuestsParam}&totalNights=${amountOfNightsParam}&pricePerNight=${pricePerNightParam}`
        );
      } catch (error) {
        toast.error("Something went wrong!");
        reset();
      } finally {
        setIsLoading(false);
      }
    }
  };

  const renderMain = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <div className="flex items-center gap-4">
          <IoCaretBackCircleOutline
            size={32}
            className="cursor-pointer hover:opacity-50"
            onClick={() => {
              handleGoBack();
            }}
          />
          <h2 className="text-3xl lg:text-4xl font-semibold">Book Listing</h2>
        </div>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <div>
            <h3 className="text-2xl font-semibold">Details</h3>
            <NcModal
              renderTrigger={(openModal) => (
                <span
                  onClick={() => openModal()}
                  className="block lg:hidden underline  mt-1 cursor-pointer"
                >
                  View booking details
                </span>
              )}
              renderContent={renderSidebar}
              modalTitle="Booking details"
            />
          </div>
          <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700 overflow-hidden z-10">
            <ModalSelectDate
              reservations={[]}
              //@ts-ignore
              listing={[]}
              renderChildren={({ openModal }) => (
                <button
                  className="text-left flex-1 p-5 flex justify-between space-x-5 hover:bg-neutral-50 dark:hover:bg-neutral-800"
                  type="button"
                >
                  <div className="flex flex-col">
                    <span className="text-sm text-neutral-400">Date</span>
                    <span className="mt-1.5 text-lg font-semibold">
                      {converSelectedDateToString([startDate, endDate])}
                    </span>
                  </div>
                </button>
              )}
            />

            <ModalSelectGuests
              renderChildren={({ openModal }) => (
                <button
                  type="button"
                  className="text-left flex-1 p-5 flex justify-between space-x-5 hover:bg-neutral-50 dark:hover:bg-neutral-800"
                >
                  <div className="flex flex-col">
                    <span className="text-sm text-neutral-400">Guests</span>
                    <span className="mt-1.5 text-lg font-semibold">
                      <span className="line-clamp-1">
                        <div className="flex flex-col">
                          Total Guests:{" "}
                          {`${
                            (guests.guestAdults || 0) +
                            (guests.guestChildren || 0) +
                            (guests.guestInfants || 0)
                          }`}
                          <div>{`Adults: ${guests.guestAdults}`}</div>
                          <div>{`Children: ${guests.guestChildren}`}</div>
                          <div>{`Infants: ${guests.guestInfants}`}</div>
                        </div>
                      </span>
                    </span>
                  </div>
                </button>
              )}
            />
          </div>
        </div>

        <div>
          <h3 className="text-2xl font-semibold">Your information</h3>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5"></div>

          <div className="mt-6">
            <Tab.Group>
              <Tab.Panels>
                <Tab.Panel className="space-y-5">
                  <div className="w-full relative">
                    <span className="w-6 h-6 text-neutral-700 absolute top-5 left-2"></span>
                    <input
                      id="email"
                      disabled={isLoading}
                      {...register("email", { required: true })}
                      placeholder=" "
                      type="text"
                      className={`
        peer
        w-full
        p-4
        pt-6
        font-semibold
        bg-white
        border-2
        rounded-md
        outline-none
        transition
        disabled:opacity-70
        disabled:cursor-not-allowed
        pl-4
        ${errs.email ? "border-red-500" : "border-gray-300"}
        `}
                    />
                    <label
                      className={`
      absolute
      text-md
      duration-150
      transform
     
      -translate-y-3
      top-4
      z-10
      origin-[0]
      left-4
      peer-placeholder-shown:scale-100
      peer-placeholder-shown:translate-y-0
      peer-focus:scale-75
      peer-focus:-translate-y-4
      `}
                    >
                      Email
                    </label>
                  </div>
                  <div className="w-full relative">
                    <span className="w-6 h-6 text-neutral-700 absolute top-5 left-2"></span>
                    <input
                      id="firstName"
                      {...register("firstName", { required: true })}
                      placeholder=" "
                      type="text"
                      className={`
        peer
        w-full
        p-4
        pt-6
        font-semibold
        bg-white
        border-2
        rounded-md
        outline-none
        transition
        disabled:opacity-70
        disabled:cursor-not-allowed
        pl-4
        ${errs.firstName ? "border-red-500" : "border-gray-300"}
        `}
                    />
                    <label
                      className={`
      absolute
      text-md
      duration-150
      transform
     
      -translate-y-3
      top-4
      z-10
      origin-[0]
      left-4
      peer-placeholder-shown:scale-100
      peer-placeholder-shown:translate-y-0
      peer-focus:scale-75
      peer-focus:-translate-y-4
      `}
                    >
                      First Name
                    </label>
                  </div>
                  <div className="w-full relative">
                    <span className="w-6 h-6 text-neutral-700 absolute top-5 left-2"></span>
                    <input
                      id="lastName"
                      disabled={isLoading}
                      {...register("lastName", { required: true })}
                      placeholder=" "
                      type="text"
                      className={`
        peer
        w-full
        p-4
        pt-6
        font-semibold
        bg-white
        border-2
        rounded-md
        outline-none
        transition
        disabled:opacity-70
        disabled:cursor-not-allowed
        pl-4
        ${errs.lastName ? "border-red-500" : "border-gray-300"}
        `}
                    />
                    <label
                      className={`
      absolute
      text-md
      duration-150
      transform
     
      -translate-y-3
      top-4
      z-10
      origin-[0]
      left-4
      peer-placeholder-shown:scale-100
      peer-placeholder-shown:translate-y-0
      peer-focus:scale-75
      peer-focus:-translate-y-4
      `}
                    >
                      Last Name
                    </label>
                  </div>
                  <div className="w-full relative">
                    <span className="w-6 h-6 text-neutral-700 absolute top-5 left-2"></span>
                    <input
                      id="phoneNumber"
                      disabled={isLoading}
                      {...register("phoneNumber", { required: true })}
                      placeholder=" "
                      type="text"
                      className={`
        peer
        w-full
        p-4
        pt-6
        font-semibold
        bg-white
        border-2
        rounded-md
        outline-none
        transition
        disabled:opacity-70
        disabled:cursor-not-allowed
        pl-4
        ${errs.phoneNumber ? "border-red-500" : "border-gray-300"}
        `}
                    />
                    <label
                      className={`
      absolute
      text-md
      duration-150
      transform
     
      -translate-y-3
      top-4
      z-10
      origin-[0]
      left-4
      peer-placeholder-shown:scale-100
      peer-placeholder-shown:translate-y-0
      peer-focus:scale-75
      peer-focus:-translate-y-4
      `}
                    >
                      Phone Number
                    </label>
                  </div>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
            <div className="pt-8">
              <ButtonPrimary
                href={""}
                onClick={() => {
                  onSubmit();
                }}
              >
                Confirm And Book
              </ButtonPrimary>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-CheckOutPagePageMain ${className}`}>
      <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
        <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">{renderMain()}</div>
        <div className="hidden lg:block flex-grow">{renderSidebar()}</div>
      </main>
    </div>
  );
};

export default CheckOutPagePageMain;
