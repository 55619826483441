import Label from "components/Label/Label";
import React, { FC, useContext, useEffect, useState } from "react";
import Avatar from "shared/Avatar/Avatar";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import Textarea from "shared/Textarea/Textarea";
import CommonLayout from "./CommonLayout";
import { Helmet } from "react-helmet";
import { UserContext } from "UserContext";
import { useNavigate } from "react-router-dom";
import useLoginModal from "hooks/useLoginModal";

export interface AccountPageProps {
  className?: string;
}

const AccountPage: FC<AccountPageProps> = ({ className = "" }) => {
  const { isAuthenticated, user } = useContext(UserContext);
  const loginModal = useLoginModal();
  const navigate = useNavigate();

  const [phoneNumber, setPhoneNumber] = useState<string>();
  useEffect(() => {
    if (user) {
      //@ts-ignore
      setPhoneNumber(user.phoneNumber);
    } else {
      navigate("/");
    }
  }, [user]);

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Account || TryBuy.club</title>
      </Helmet>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">Account infomation</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex flex-col md:flex-row">
            <div className="flex-shrink-0 flex items-start">
              <div className="relative rounded-full overflow-hidden flex">
                <Avatar sizeClass="w-32 h-32" userName={user?.name} />
              </div>
            </div>
            <div className="flex-grow mt-10 md:mt-0 md:pl-16 max-w-3xl space-y-6">
              <div>
                <Label>Name</Label>
                <Input className="mt-1.5" defaultValue={user?.name} />
              </div>
              <div>
                <Label>Email</Label>
                <Input className="mt-1.5" defaultValue={user?.email} />
              </div>
              {/* ---- */}
              <div>
                <Label>Phone number</Label>
                <Input className="mt-1.5" defaultValue={phoneNumber} />
              </div>
              {/* ---- */}
              {/* <div className="pt-2">
                <ButtonPrimary>Update info</ButtonPrimary>
              </div> */}
            </div>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPage;
