import Heading from "components/Heading/Heading";
import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import HIW1img from "images/HIW2-1-dark.png";
import HIW2img from "images/HIW2-2-dark.png";
import HIW3img from "images/HIW2-3-dark.png";
import VectorImg from "images/VectorHIW.svg";

export interface SectionHowItWorkProps {
  className?: string;
  data?: {
    id: number;
    title: string;
    desc: string;
    img: string;
    imgDark?: string;
  }[];
}

const DEMO_DATA: SectionHowItWorkProps["data"] = [
  {
    id: 1,
    img: HIW1img,
    title: "Browse Properties",
    desc: "Explore our diverse range of properties available for trial.",
  },
  {
    id: 2,
    img: HIW2img,
    title: "Experience It Firsthand",
    desc: "Step inside the property and experience its features, ambiance, and surroundings firsthand.",
  },
  {
    id: 3,
    img: HIW3img,
    title: "Make an Informed Decision",
    desc: "With firsthand experience, make an informed decision about purchasing the property.",
  },
];

const SectionHowItWork: FC<SectionHowItWorkProps> = ({ className = "", data = DEMO_DATA }) => {
  return (
    <div className={`nc-SectionHowItWork  ${className}`} data-nc-id="SectionHowItWork">
      <Heading isCenter desc="TryBuy">
        How it works
      </Heading>
      <div className="mt-20 relative grid md:grid-cols-3 gap-20">
        <img className="hidden md:block absolute inset-x-0 top-10" src={VectorImg} alt="" />
        {DEMO_DATA.map((item) => (
          <div key={item.id} className="relative flex flex-col items-center max-w-xs mx-auto">
            {item.imgDark ? (
              <>
                <NcImage
                  containerClassName="dark:hidden block mb-8 max-w-[200px] mx-auto"
                  src={item.img}
                />
                <NcImage
                  containerClassName="hidden dark:block mb-8 max-w-[200px] mx-auto"
                  src={item.imgDark}
                />
              </>
            ) : (
              <NcImage containerClassName="mb-8 max-w-[200px] mx-auto" src={item.img} />
            )}
            <div className="text-center mt-auto">
              <h3 className="text-xl font-semibold">{item.title}</h3>
              <span className="block mt-5 text-neutral-500 dark:text-neutral-400">{item.desc}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionHowItWork;
